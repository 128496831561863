@use "@/styles/abstracts" as *;

.sr-only {
	@include hide;
}

html.prevent-scroll,
html:has(body[data-scroll-locked]) {
	overflow: hidden !important;
	> body {
		height: 100%;
		overflow-y: scroll !important;
		overflow-x: hidden !important;
		position: relative !important;
	}
}

.overflow-hidden {
	overflow: hidden !important;
}
.overflow-scroll {
	overflow-y: scroll !important;
}

// iOS fix :(
body.overflow-hidden {
	position: relative;
}
