@use "@/styles/abstracts" as *;

.col-full {
	grid-column: 1/-1;
}

@for $n from 1 through 12 {
	.grid-cols-#{$n} {
		display: grid;
		grid-template-columns: repeat($n, minmax(auto, 1fr));
	}
	.col-#{$n} {
		grid-column: auto / span $n;
	}
	.col-start-#{$n} {
		grid-column-start: $n;
	}
}
@each $bp, $value in ((xs: $xs, sm: $sm, md: $md, lg: $lg, xl: $xl)) {
	@for $n from 1 through 12 {
		@include breakpoint($value) {
			.col-#{$bp}-full {
				grid-column: 1/-1;
			}
		}
	}
}
@each $bp, $value in ((xs: $xs, sm: $sm, md: $md, lg: $lg, xl: $xl)) {
	@for $n from 1 through 12 {
		@include breakpoint($value) {
			.col-#{$bp}-#{$n} {
				grid-column: auto / span $n;
			}
		}
	}
}
@each $bp, $value in ((xs: $xs, sm: $sm, md: $md, lg: $lg, xl: $xl)) {
	@include breakpoint($value) {
		@for $n from 1 through 12 {
			.col-start-#{$bp}-#{$n} {
				grid-column-start: $n;
			}
		}
	}
}
