@use "@/styles/abstracts" as *;

:root {
	--header-height: #{$primary-nav-height-mobile};
	--container-gutter: #{$container-gutter--sm};
	@include breakpoint($md) {
		--container-gutter: #{$container-gutter--md};
	}
	@include breakpoint($lg) {
		--container-gutter: #{$container-gutter};
	}
	@include breakpoint($xl) {
		--header-height: #{calc($primary-nav-height + $secondary-nav-height)};
	}
}

html {
	box-sizing: border-box;
	height: 100%;
	overflow: hidden;
	background-color: $color-purple--dark;
	color-scheme: dark;
	scroll-padding-top: var(--header-height);
}

body {
	height: 100%;
	// background-color: $color-orange--light;
	font-family: $font-stack;
	font-weight: $fw-regular;
	color: $color-text;
	font-weight: $fw-regular;
	font-size: rem(16);
	overflow-x: hidden;
	position: relative;
	z-index: -2;
	scroll-padding-top: var(--header-height);
}

iframe {
	max-width: 100%;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

picture {
	display: block;
}

img {
	display: block;
	max-width: 100%;
	margin: 0;
}

svg {
	min-height: 0;
	min-width: 0;
	display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 1em 0;

	&:first-child {
		margin-top: 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: $fw-medium;
}

:focus-visible {
	outline: 3px dashed currentColor;
	outline-offset: 2px;
}

h1 {
	@include h1;
}
h2 {
	@include h2;
}
h3 {
	@include h3;
}
h4 {
	@include h4;
}
h5 {
	@include h5;
}
